import React from 'react'
import { Box, Image } from '@vtex/store-ui'
import { css, keyframes } from '@emotion/core'

import styles from './styles.json'

const SpotWatch = () => {
  const myEffect = keyframes`
    0% {
      opacity: 0;
      transform: translateY(0px) translateX(-50%);
    }
    50% {
      opacity: 0;
      transform: translateY(10px) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(-0px) translateX(-50%);
    }
  `

  const animatedItem = css`
    animation: ${myEffect} 0.3s linear;
  `

  return (
    <Box as="span" sx={styles.cronometerContainer} css={animatedItem}>
      <Box sx={styles.cronometerContainer.item}>
        <Image
          src="https://avongroup.vteximg.com.br/arquivos/discount-highlight.png"
          alt="Cronômetro"
        />
      </Box>
      <Box sx={styles.cronometerContainer.item}>
        <Image
          src="https://avongroup.vteximg.com.br/arquivos/discount-highlight.png"
          alt="Cronômetro"
        />
      </Box>
      <Box sx={styles.cronometerContainer.item}>
        <Image
          src="https://avongroup.vteximg.com.br/arquivos/discount-highlight.png"
          alt="Cronômetro"
        />
      </Box>
    </Box>
  )
}

export default SpotWatch

import React, { useState, useEffect } from 'react'
import { Box, Text, Button, Flex } from '@vtex/store-ui'
import { Link } from 'gatsby'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import { ArrowLeft } from 'src/components/icons'

import styles from './styles.json'

interface Props {
  sections: Array<{
    props: {
      title: string
      url: string
      searchParams: {
        collection: string
        from: number
        hideUnavailableItems: boolean
        orderBy: string
        to: number
      }
    }
  }>
}

const CategoryShelf = ({ sections }: Props) => {
  const [current, setCurrent] = useState<number>(0)
  const [currentCategoryName, setCurrentCategoryName] = useState('')
  const [viewMore, setViewMore] = useState<boolean>(false)

  const handleViewMore = () => {
    setViewMore(true)
  }

  const handleViewLess = () => {
    setViewMore(false)
  }

  const shelfData = [
    {
      id: 0,
      category: 'Maquiagens',
      categoryGraphQl: 8,
      categoryText: (
        <Text>
          Comprar maquiagem com desconto nunca foi tão fácil! Na Best Friday
          Avon você encontra maquiagens de alta qualidade.
          {!viewMore && <Button onClick={handleViewMore}>...Ver mais.</Button>}
          <Box sx={{ display: viewMore ? 'block' : 'none' }}>
            <Text as="p">
              São maquiagens de alta qualidade, que contribuem para uma pele bem
              cuidada, hidratada e protegida dos raios solares. Você nunca viu
              uma promoção de maquiagem como essa! E, para se preparar ainda
              mais para a Black Friday de maquiagem, responda este{' '}
              <Link to="/encontresuacor">quiz</Link> e descubra qual o seu tom
              de produtos para a pele.
              <Button onClick={handleViewLess}>...Ver menos.</Button>
            </Text>
          </Box>
        </Text>
      ),
    },
    {
      id: 1,
      category: 'Perfumes',
      categoryGraphQl: 9,
      categoryText: (
        <Text>
          Precisando renovar o perfume? Então fique de olho nas ofertas que vão
          acontecer na Black Friday de perfumes Avon!
          {!viewMore && <Button onClick={handleViewMore}>...Ver mais.</Button>}
          <Box sx={{ display: viewMore ? 'block' : 'none' }}>
            <Text as="p">
              Serão muitas ofertas de perfumes para aproveitar. Sua melhor opção
              para comprar perfume masculino na Black Friday é com a Avon. Não é
              à toa que nós chamamos essa data de Best Friday por aqui. E se
              você quer aproveitar os descontos em perfumes da melhor forma, é
              só responder ao <Link to="/quiz-perfume">nosso quiz</Link> e
              descobrir qual perfume mais combina com você!
              <Button onClick={handleViewLess}>...Ver menos.</Button>
            </Text>
          </Box>
        </Text>
      ),
    },
    {
      id: 2,
      category: 'Skincare',
      categoryGraphQl: 10,
      categoryText: (
        <Text>
          Na Best Friday Avon você encontra o melhor do skincare: qualidade e
          preços incríveis!
          {!viewMore && <Button onClick={handleViewMore}>...Ver mais.</Button>}
          <Box sx={{ display: viewMore ? 'block' : 'none' }}>
            <Text as="p">
              O inverno ficou para trás, e agora os dias começam a esquentar.
              Você já pensou em aproveitar a Black Friday Skincare e preparar
              sua pele para o calor? Na Best Friday Avon você encontra o melhor
              kit skincare: barato e com muita qualidade para você! Protetor
              solar e hidratante facial na Black Friday não vão faltar! Tudo
              para deixar a sua pele linda e protegida nessa nova estação. Quer
              se preparar ainda mais? Nós vamos criar um ritual de skincare
              personalizado para você! É só responder esse{' '}
              <Link to="/quiz-pele">quiz</Link> e começar a aplicar.
              <Button onClick={handleViewLess}>...Ver menos.</Button>
            </Text>
          </Box>
        </Text>
      ),
    },
    {
      id: 3,
      category: 'Unhas',
      categoryGraphQl: 11,
      categoryText: (
        <Text>
          Para quem gosta de ter sempre as unhas feitas e bem cuidadas, não pode
          deixar de aproveitar a Black Friday Unhas da Avon.
          {!viewMore && <Button onClick={handleViewMore}>...Ver mais.</Button>}
          <Box sx={{ display: viewMore ? 'block' : 'none' }}>
            <Text as="p">
              São muitos esmaltes em oferta para qualquer ocasião. Além disso, a
              Avon trabalha com acessórios como kit polidor de unhas, kit
              alicate, pincéis e espátulas. Tudo para que você aproveite a Black
              Friday da melhor forma aqui na Best Friday Avon.
              <Button onClick={handleViewLess}>...Ver menos.</Button>
            </Text>
          </Box>
        </Text>
      ),
    },
  ]

  const { length } = shelfData

  useEffect(() => {
    const selectedCategory = shelfData.filter((item) => {
      return item.id === current
    })[0].category

    setCurrentCategoryName(selectedCategory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, currentCategoryName])

  if (!Array.isArray(shelfData) || shelfData.length <= 0) {
    return null
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  return (
    <Box sx={styles}>
      <Box sx={styles.container}>
        <Box sx={styles.titleContent}>
          <Text as="h3">
            As melhores ofertas das categorias queridinhas da Avon
          </Text>
        </Box>
        <Box sx={styles.categorySection}>
          <Box sx={styles.categorySection.box}>
            <Box sx={styles.categorySection.text}>
              <Text as="h4">Best Friday</Text>
              <Flex sx={styles.categorySection.controls}>
                <Button onClick={prevSlide}>
                  <ArrowLeft />
                </Button>
                <Text as="h5">{currentCategoryName}</Text>
                <Button onClick={nextSlide}>
                  <ArrowLeft />
                </Button>
              </Flex>
              <Flex sx={styles.categorySection.dots}>
                {shelfData.map((item, index: number) => (
                  <Box
                    key={item.id}
                    sx={
                      index === current
                        ? styles.categorySection.dots.itemActive
                        : styles.categorySection.dots.itemInactive
                    }
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
        <Box>
          {shelfData.map((item, index: number) => {
            return (
              <Box
                key={item.id}
                sx={
                  index === current
                    ? styles.categoryContentActive
                    : styles.categoryContentInactive
                }
              >
                <Box sx={styles.categoryContentActive.text}>
                  {item.categoryText}
                </Box>
                <Box sx={styles.categoryContentActive.shelf}>
                  <Shelf
                    {...sections[item.categoryGraphQl].props}
                    showArrows
                    ProductSummary={ProductSummary}
                  />
                  <Box sx={styles.buttonLink}>
                    <Link to={sections[item.categoryGraphQl].props.url}>
                      Ver todos
                    </Link>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default CategoryShelf

import React, { useState, useEffect } from 'react'
import type { FC } from 'react'
import { Box, Text } from '@vtex/store-ui'

import styles from './styles.json'

interface Props {
  countDate: string
}

const CountDown: FC<Props> = ({ countDate }) => {
  const [finalCountDown, setFinalCountDown] = useState('')

  useEffect(() => {
    setInterval(() => {
      const countdownDate = new Date(countDate).getTime()
      const dateNow = new Date().getTime()
      const gap = countdownDate - dateNow

      const standardSecond = 1000
      const standardMinute = standardSecond * 60
      const standardHour = standardMinute * 60
      const standardDay = standardHour * 24

      const day = Math.floor(gap / standardDay)
      const hour = Math.floor((gap % standardDay) / standardHour)
      const minutes = Math.floor((gap % standardHour) / standardMinute)
      const seconds = Math.floor((gap % standardMinute) / standardSecond)

      if (gap <= 0) {
        setFinalCountDown('Acabou :(')
      } else if (day === 0) {
        setFinalCountDown(`
          ${hour.toString().padStart(2, '0')}:
          ${minutes.toString().padStart(2, '0')}:
          ${seconds.toString().padStart(2, '0')}
        `)
      } else {
        setFinalCountDown(`
          ${day.toString().padStart(2, '0')}:
          ${hour.toString().padStart(2, '0')}:
          ${minutes.toString().padStart(2, '0')}:
          ${seconds.toString().padStart(2, '0')}
        `)
      }
    }, 1000)
  }, [finalCountDown, countDate])

  return (
    <Box sx={styles}>
      <Box sx={styles.countDownContent}>
        <Text as="h5">{finalCountDown}</Text>
      </Box>
    </Box>
  )
}

export default CountDown

import React from 'react'
import { Box } from '@vtex/store-ui'
import CommonQuestions from 'src/components/common/CommonQuestions'

import styles from './styles.json'

const Questions = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.wrapper.questionsContainer}>
          <CommonQuestions />
        </Box>
      </Box>
    </Box>
  )
}

export default Questions

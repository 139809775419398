import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import type { BlackFridayAvonQueryQuery } from 'src/pages/black-friday-avon/__generated__/BlackFridayAvonQuery.graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

type Props = PageProps<BlackFridayAvonQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata =
    props.data.cmsInstitutionalPage?.seo?.siteMetadataWithSlug

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)
  const { isRepresentativeSelected } = useRepresentative()

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.avon.com.br/black-friday-avon"
        />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title as string}
        description={siteMetadata?.description as string}
        titleTemplate={siteMetadata?.title ?? '%s | Avon'}
        noindex={!!isRepresentativeSelected}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
